export const initDataLayer = (scope = document) => {
  for (const el of scope.querySelectorAll('[data-event-label]')) {
    const { eventLabel, eventAction } = el.dataset;
    if (!(eventLabel && eventAction)) continue;

    el.addEventListener('click', () => {
      window.parent.postMessage({
        message: 'dataLayer',
        event: 'uaevent',
        ecommerce: undefined,
        eventCategory: 'store locator',
        pageUrl: window.parent.location.href,
        eventAction,
        eventLabel,
      }, '*');
    });
  }
};

export const initFacetListeners = (data) => {
  const { _container: container } = data;
  if (!container) return;

  for (const fieldset of container.querySelectorAll('.yxt-FilterOptions-fieldSet')) {
    const { label: filterName } = fieldset.dataset;
    const input = fieldset.querySelector('.js-yext-filter-option');
    if (!(filterName && input) || input.dataset.listenerAdded) continue;

    input.addEventListener('change', () => {
      window.parent.postMessage({
        message: 'dataLayer',
        event: 'uaevent',
        ecommerce: undefined,
        eventCategory: 'store locator',
        eventAction: 'filter',
        eventLabel: filterName,
        pageUrl: window.parent.location.href,
      }, '*');
    });

    input.dataset.listenerAdded = 'true';
  }
}

export const initSubmitListener = (data) => {
  const { _container: container } = data;
  if (!container) return;

  const form = container.querySelector('.yxt-SearchBar-form');
  const input = container.querySelector('.js-yext-query');
  if (!(form && input) || form.dataset.listenerAdded) return;

  form.addEventListener('submit', () => {
    window.parent.postMessage({
      message: 'dataLayer',
      event: 'uaevent',
      ecommerce: undefined,
      eventCategory: 'store locator',
      eventAction: 'find_a_store',
      eventLabel: input.value,
      pageUrl: window.parent.location.href,
    });
  });

  form.dataset.listenerAdded = 'true';
}
